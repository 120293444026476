import React from "react";
import Section from "../../components/section";
import Layout from "../../components/layout/layout";
import dash from "../../images/Dash-Screen-for-Website.png";
import TriangleTwo from "../../images/triangle_two.png";
import TriangleThree from "../../images/triangle_three.png";
import Button from "../..//components/button";
import logo from "../../../static/hexowatch-logo.png";
import impactLogo from "../../images/affiliate/impact-logo.svg";
import portfolioIcon from "../../images/affiliate/portfolio-icon.svg";
import ecommerceIcon from "../../images/affiliate/ecommerce-icon.svg";
import agencyIcon from "../../images/affiliate/agency-icon.svg";
import illustrationHexowatch from "../../images/affiliate/illustration-hexowatch.png";
import earningPotential from '../../images/affiliate/earning-potential.png';
// import Typewriter from 'typewriter-effect';
import "./styles.scss";

const Affiliate: React.FC = (): JSX.Element => {
  // const cookiePadding = getCookiePolicy() === "registered" ? 'no-cookie' : 'with-cookie';

  return (
    <Layout>
      <div className="affiliate-page">
        <section id='seo_home_area' className={`seo_home_area`}>
          <div className="container_home">
            <div className="row_home">
              <div
                className="col-lg-6 d-flex align-center"
                style={{ verticalAlign: "middle" }}
              >
                <div className="prototype_content_home">
                  <h4 className="h4-title f_size_24 t_color3">
                    Become a Hexowatch affiliate partner and earn 20% commission
                    on every sale and renewal.
                  </h4>
                  <p className="desc_1">
                    Hexowatch has partnered with Impact to power their affiliate
                    program for world class tracking and convenient payouts.
                  </p>
                  <Button
                    primary
                    className="trial_btn"
                    onClick={() =>
                      window.open(
                        "https://app.impact.com/campaign-promo-signup/Hexact.brand"
                      )
                    }
                  >
                    Sign up now
                  </Button>
                  <div className="logos">
                    <img
                      src={logo}
                      className="hexometer_logo"
                      alt="Hexowatch.com"
                    />
                    <div className="divider"></div>
                    <img
                      src={impactLogo}
                      className="affiliate_logo"
                      alt="impact.com"
                    />
                  </div>
                  <h4 className="h4-title f_size_24 t_color3">
                    How much can you earn?
                  </h4>
                  <p className="desc_1">
                    Our affiliate program is the perfect way to generate
                    recurring revenue by introducing Hexowatch to your audience
                    and local businesses.
                  </p>
                  <p className="desc_1">
                    Hexowatch has plans for all business sizes from solopreneurs
                    to established agencies handling hundreds of clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-center img header-img-block">
                <img
                  src={dash}
                  alt="AI sidekick to protect and grow eCommerce business"
                  style={{
                    maxWidth: "100%",
                    marginRight: "-20px"
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <Section>
          <div
            className="col-12 d-block mb_20"
            style={{ maxWidth: "1200px", width: "100%" }}
          >
            <div className="row">
              <div className="col-lg-6 selling-item">
                <div className="img-circle">
                  <img src={portfolioIcon} alt={"portfolio"} />
                </div>
                <h5>Selling to large agencies</h5>
                <p>
                  If you sold just 10 Business + annual plans this month, you
                  could earn a sweet
                  <span className="mark">$1,998</span> and every year collect up
                  to <span className="mark">$1,998*</span> in commissions as
                  long as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Business + plans a month and you could earn up to{" "}
                  <span className="mark">$23,976</span> a year after just one
                  year*
                </p>
                <p>
                  *Based on customers renewing their Business + plans on a
                  yearly basis.
                </p>
              </div>
              <div className="col-lg-6 selling-item">
                <div className="img-circle ">
                  <img src={agencyIcon} alt={"agency"} />
                </div>
                <h5>Selling to smaller agencies</h5>
                <p>
                  If you sold just 10 Business annual plans this month, you
                  could earn a sweet
                  <span className="mark">$999</span> and every year collect up
                  to <span className="mark">$999**</span> in commissions as long
                  as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Business plans a month and you could earn up to{" "}
                  <span className="mark">$11,997</span> a year after just one
                  year**.
                </p>
                <p>
                  **Based on customers renewing their Business plans on a yearly
                  basis.
                </p>
              </div>
              <div className="col-lg-6 selling-item">
                <div className="img-circle">
                  <img src={ecommerceIcon} alt={"exommerce"} />
                </div>
                <h5>Selling to eCommerce businesses</h5>
                <p>
                  If you sold just 10 Pro annual plans this month, you could
                  earn a sweet <span className="mark">$499</span> and every year
                  collect up to <span className="mark">$499***</span> in
                  commissions as long as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Pro plans a month and you could earn up to{" "}
                  <span className="mark">$5,997</span> a year after just one
                  year***.
                </p>
                <p>
                  ***Based on customers renewing their Pro plans on a yearly
                  basis.
                </p>
              </div>
              <div className="col-lg-6 selling-item selling-img">
                <img
                  src={illustrationHexowatch}
                  className="illustration-img"
                  alt="illustration"
                />
              </div>
            </div>
            <div className="row row-reverse">
              <div className="col-lg-6 d-flex">
                <div className="m-auto text-center text-lg-left">
                  <h5 className="text-center text-lg-left">Tap into unlimited earning potential</h5>
                  <p className="text-center text-lg-left">
                    Earn commissions on all purchases made by new clients you refer across Hexomatic, Hexowatch,
                    Hexometer, Hexospark, and premium credit subscriptions.
                  </p>
                  <Button
                    primary
                    className="trial_btn"
                    onClick={() => window.open('https://app.impact.com/campaign-promo-signup/Hexact.brand')}
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img src={earningPotential} style={{maxWidth: '450px', width: '100%', margin: 'auto'}} alt='Earning Potential' />
              </div>
            </div>
          </div>
            {/* <div className="start-building">
              <p>
                Start building recurring passive income by introducing Hexowatch
                to businesses who need to stay on top of their market and
                automate their competitor monitoring.
              </p>
              <Button
                primary
                className="trial_btn"
                onClick={() =>
                  window.open(
                    "https://app.impact.com/campaign-promo-signup/Hexact.brand"
                  )
                }
              >
                Sign up now
              </Button>
            </div>
          </div> */}
        </Section>
      </div>
    </Layout>
  );
};

export default Affiliate;
